import * as React from "react"
import { SVGProps } from "react"

// Styling
import colors from "../../styles/colors"

// Constants
import { xmlNamespace } from "../../constants/svgUrl"

export const ServicesIntroSmallSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={320}
      height={78}
      viewBox="0 0 320 78"
      fill="none"
      xmlns={xmlNamespace}
      {...props}
    >
      <g clipPath="url(#clip0_1435_10997)">
        <path
          d="M0 0H319.778V50.5C319.778 50.5 287.8 75 266.482 75C245.547 75 233.949 48.5 212.963 48.5C192.184 48.5 197.752 44 176.877 44C154.083 44 147.675 64 121.36 44C89.1643 19.5305 57.1825 48.5 33.5323 48.5C17.1106 48.5 0 12 0 12V0Z"
          fill={colors.purpleLight}
        />
        <path
          d="M319.778 1H0V18.5C0 18.5 25.8696 33.7042 47.0784 35C68.011 36.2789 87.4417 37.1823 108.369 35C129.141 32.834 187.981 67 187.981 67C187.981 67 218.605 76.3864 238.279 77.5C262.704 78.8825 281.56 49 304.344 49C321.554 49 319.778 34.0871 319.778 34.0871V1Z"
          fill="#90CDB7"
        />
        <path
          d="M319.778 0H0V34.6718V56C20.9326 57.2789 72.1919 9.61497 110.368 29.0871C124.778 36.437 123.1 34.3942 137.682 41.5C152.072 48.5119 166.561 63.6602 180.985 69C218.18 82.7691 253.717 56.7634 276.475 51.8553C293.514 48.1805 319.778 29.0871 319.778 29.0871V0Z"
          fill="#F1C48A"
        />
        <path
          d="M0 0H319.778V49.3013C319.778 49.3013 271.218 67.9382 239.944 68C208.499 68.0622 159.889 49.3013 159.889 49.3013C159.889 49.3013 109.964 26.0303 77.8348 26.5113C47.3487 26.9677 0 49.3013 0 49.3013V0Z"
          fill="#EFEDF8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1435_10997">
          <rect width={320} height={78} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ServicesIntroLargeSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={1441}
      height={78}
      viewBox="0 0 1441 78"
      fill="none"
      xmlns={xmlNamespace}
      {...props}
    >
      <path
        d="M0 0H1440V50.5C1440 50.5 1296 75 1200 75C1105.73 75 1053.5 48.5 959 48.5C865.429 48.5 890.5 44 796.5 44C693.857 44 665 64 546.5 44C401.518 19.5305 257.5 48.5 151 48.5C77.0513 48.5 0 12 0 12V0Z"
        fill={colors.purpleLight}
      />
      <path
        d="M1440 1H0V18.5C0 18.5 116.494 33.7042 212 35C306.262 36.2789 393.761 37.1823 488 35C581.537 32.834 846.5 67 846.5 67C846.5 67 984.404 76.3864 1073 77.5C1182.99 78.8825 1267.9 49 1370.5 49C1448 49 1440 34.0871 1440 34.0871V1Z"
        fill="#90CDB7"
      />
      <path
        d="M1440 0H0V34.6718V56C94.262 57.2789 325.089 9.61497 497 29.0871C561.889 36.437 554.335 34.3942 620 41.5C684.797 48.5119 750.044 63.6602 815 69C982.494 82.7691 1142.52 56.7634 1245 51.8553C1321.73 48.1805 1440 29.0871 1440 29.0871V0Z"
        fill="#F1C48A"
      />
      <path
        d="M0 0H1440V49.3013C1440 49.3013 1221.33 67.9382 1080.5 68C938.897 68.0622 720 49.3013 720 49.3013C720 49.3013 495.182 26.0303 350.5 26.5113C213.217 26.9677 0 49.3013 0 49.3013V0Z"
        fill="#EFEDF8"
      />
    </svg>
  )
}
