import React from "react"
import styled from "styled-components"

// Componnets
import Button from "../../atoms/Button"

// constants
import { useLocale } from "../../../hooks/useLocale"

// Styling
import colors from "../../../styles/colors"
import { fontWeights } from "../../../styles/textStyles"
import mq from "../../../styles/breakpoints"
import { fonts } from "../../../styles/fonts"

// Types
import { ICardProps } from "../../../prismic-types/templates/services-template"

interface IProps {
  card: ICardProps
}

const CardSection: React.FC<IProps> = ({ card }) => {
  const { getLocalizedLink } = useLocale()

  const columns = card.cardColumns.length

  return (
    <IntroCard>
      <CartHeader>
        <CardTextWrapper>
          <CardTitle>{card.cardTitle}</CardTitle>
          <CardPricing>{card.cardDescription}</CardPricing>
        </CardTextWrapper>
        <StyledButton
          label={card.cardButton}
          href={getLocalizedLink("/contact")}
          fullWidth={false}
        />
      </CartHeader>
      <CardColumns columns={columns}>
        {card.cardColumns.map((column, index) => (
          <CardColumn key={index}>
            <CardColumnTitle>{column.cardTitle}</CardColumnTitle>
            <CardColumnDescription>
              {column.cardDescription}
            </CardColumnDescription>
          </CardColumn>
        ))}
      </CardColumns>
    </IntroCard>
  )
}

const IntroCard = styled.div`
  position: relative;
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    rgba(92, 71, 174, 1) 0%,
    rgba(113, 95, 185, 1) 52%,
    rgba(136, 121, 197, 1) 82%,
    rgba(169, 158, 213, 1) 100%,
    rgba(255, 255, 255, 0) 100%
  );

  box-shadow: 0px 12px 48px 0px rgba(42, 9, 134, 0.25),
    0px 0px 5px 0px rgba(26, 6, 80, 0.1);
  border-radius: 24px;
  padding: 32px;
  gap: 24px;
  margin-top: -140px;
  z-index: 10;

  ${mq.from.S`
    padding: 48px;
    margin-top: -160px;
  `}

  ${mq.from.L`
    margin: 0 auto;
    margin-top: -180px;
    width: 1060px;
  `}

  ${mq.from.XL`
    margin-top: -190px;
  `}

   ${mq.from.XXL`
    margin-top: -250px;
  `}
`

const CartHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${mq.from.S`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  `}
`

const CardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const CardTitle = styled.h3`
  font-family: ${fonts.primary};
  font-size: 24px;
  line-height: 34px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -1px;
  color: ${colors.white};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 32px;
    line-height: 40px;
  `}
`

const CardPricing = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: ${fontWeights.regular};
  letter-spacing: -1%;
  color: ${colors.white};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 20px;
    line-height: 32px;
  `}
`

const StyledButton = styled(Button)`
  background-color: ${colors.white};
  color: ${colors.purpleLight};
  width: 100%;

  ${mq.from.S`
    width: auto;
  `}
`

const CardColumns = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 30px;

  ${mq.from.S`
    grid-template-columns: ${({ columns }: { columns: number }) =>
      `repeat(${columns}, 1fr)`};
  `}
`

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`

const CardColumnTitle = styled.h5`
  font-family: ${fonts.primary};
  font-size: 18px;
  line-height: 26px;
  font-weight: ${fontWeights.medium};
  color: ${colors.white};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 22px;
    line-height: 30px;
  `}
`

const CardColumnDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${fontWeights.regular};
  color: ${colors.white};
  letter-spacing: -1%;
  margin-bottom: 0px;
`

export default CardSection
